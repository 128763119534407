import styled, { css } from 'styled-components';
export const MainTitle = styled.h1`
  display: flex;
  flex-flow: row wrap;
  justify-content: center; 
  font-size: 2.5rem;
  margin-bottom: 1.1rem;
  @media (max-width: ${({ theme, breakpoint = 'small' }) => theme[`${breakpoint}Breakpoint`]}) {
    font-size: 2rem;
  }
  ${({ centered }) =>
    centered &&
    css`
      justify-content: center;
    `}
`;
MainTitle.displayName = 'MainTitle';

export const MainSubTitle = styled.div`
  display: flex;
  justify-content: center; 
  flex-flow: row wrap;
  font-size: 1.15rem;
  margin-bottom: 4.5rem;
  @media (max-width: ${({ theme, breakpoint = 'small' }) => theme[`${breakpoint}Breakpoint`]}) {
    font-size: 1rem;
    margin-bottom: 2.5rem;
  }

  ${({ centered }) =>
    centered &&
    css`
      justify-content: center;
    `}
`;
MainSubTitle.displayName = 'MainSubTitle';